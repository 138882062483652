import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34')
];

export const server_loads = [];

export const dictionary = {
		"/": [~8],
		"/(app)/(navbar)/calendar": [9,[2,3]],
		"/(app)/(navbar)/courses": [10,[2,3]],
		"/(app)/(navbar)/courses/create": [17,[2,3]],
		"/(app)/(navbar)/courses/[slug]": [11,[2,3]],
		"/(app)/(navbar)/courses/[slug]/email/transactional/confirmation": [12,[2,3]],
		"/(app)/(navbar)/courses/[slug]/form": [13,[2,3]],
		"/(app)/(navbar)/courses/[slug]/participants": [~14,[2,3]],
		"/(app)/(navbar)/courses/[slug]/participants/[participant_id]": [15,[2,3]],
		"/(app)/(navbar)/courses/[slug]/sessions": [16,[2,3]],
		"/(auth)/login": [26,[5]],
		"/(app)/onboarding": [25,[2]],
		"/(app)/(navbar)/participants": [18,[2,3]],
		"/(app)/(navbar)/participants/[slug]": [19,[2,3]],
		"/(share)/payment-confirmation": [27,[6]],
		"/(share)/register/[slug]": [~28,[6]],
		"/(share)/register/[slug]/participant/[participant_id]/confirmation": [~29,[6]],
		"/(app)/(navbar)/settings": [20,[2,3]],
		"/(app)/(navbar)/settings/billing": [21,[2,3]],
		"/(share)/share/calendar/[slug]": [~30,[6]],
		"/(share)/share/subscription-plans/[slug]": [~31,[6,7]],
		"/(share)/share/subscription-plans/[slug]/payment/[subscription_id]": [~32,[6,7]],
		"/(share)/share/subscription-plans/[slug]/payment/[subscription_id]/confirmation": [~33,[6,7]],
		"/(app)/(navbar)/subscriptions": [22,[2,3,4]],
		"/(app)/(navbar)/subscriptions/plans": [23,[2,3,4]],
		"/(app)/(navbar)/subscriptions/plans/create": [24,[2,3,4]],
		"/(share)/tools/dance/find-dance-tunes": [34,[6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';