import type { Handle } from '@sveltejs/kit';

import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_SENTRY_DSN, PUBLIC_APP_ENVIRONMENT } from '$env/static/public';

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
	dsn: PUBLIC_SENTRY_DSN,
	tracesSampleRate: 1,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1,
	integrations: [new Sentry.Replay()],
	environment: PUBLIC_APP_ENVIRONMENT
});

export const handleError = Sentry.handleErrorWithSentry();

export const handle: Handle = async ({ event, resolve }) => {
	const response = await resolve(event);
	return response;
};
